import Head from "next/head";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Component404 from "../components/Component404";

export default function Page404() {
  return (
    <div>
      <Head>
        <title>Page not found - Long Lead</title>
      </Head>
      <Header />
      <main className="main-container">
        <Component404 />
        <div id="footer-trigger" />
      </main>
      <Footer />
    </div>
  );
}
